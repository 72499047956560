

import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ContactForm from '../components/ContactForm'
import Img from "gatsby-image";
import HowItWorks from "../components/HowItWorks";
import AgentvsSellFastSactable from '../components/AgentvsSellFastSacTable'
import brandLogos from "../settings/company_brand.json";
import SeeTheDifferenceSidebar from '../components/SeeTheDifferenceSidebar'

const FAQPage = () => {
 
  return (
    <Layout headerTransparent={false}>
      <SEO
        title={'How To Sell Your House in Sacramento | FAQ'}
        description={'Do you want to sell your house in Sacramento? If so, you may have some questions about how a direct sale works. Get all the answers you need here!'}
        keywords={''}
      />






      <section className="py-16 section-stripe-bg bg-gray-50 ">
        <div className="flex flex-wrap px-6 md:px-8 container mx-auto ">
          <div className="w-full md:w-2/3 md:pr-4 prose">


            <h1>Frequently Asked Questions</h1>
            <p>Do you want to <a href="/sell-your-house/" data-type="page">sell your house</a> in Sacramento? If so, you may have some questions about selling your property directly. We get a lot of questions from the sellers we work with and we have answered the most popular ones below. <span>If you have a different question, don’t hesitate to <a href="/contact-us/">contact us</a> or give us a call at 916-237-9457!</span></p>
             

<p>Do you want to <a href="/sell-your-house/" data-type="page">sell your house</a> in Sacramento? If so, you may have some questions about selling your property directly. We get a lot of questions from the sellers we work with and we have answered the most popular ones below. <span>If you have a different question, don’t hesitate to <a href="/contact-us/">contact us</a> or give us a call at 916-237-9457!</span></p>



<p><strong>Q: &nbsp;Do you list houses or buy them outright?</strong><br/> A:&nbsp;&nbsp; <span>At Sell Your Home For Cash Sacramento, we buy houses outright. We are not putting them on the MLS or selling in exchange for a commission. In fact, we are so serious about buying your house, that we will even offer a $5,000 non-refundable deposit when you accept our offer. </span></p>



<p><strong>Q: &nbsp;How do you determine what to offer for a house?</strong><br/> A:&nbsp; To determine our offer prices we will first look at comparable sales in the area. We will then factor in our repair costs which are typically very low. We know how to fix up houses without spending too much money. From there, we will create an offer that is fair and direct. </p>



<p><strong>Q: &nbsp;Where do you buy houses?</strong><br/> A:&nbsp; Currently, we are buying houses in Sacramento, Carmichael, Del Paso Heights, North Highlands, Oak Park, Fair Oaks, Citrus Heights, Arden-Arcade, Roseville, Orangevale, and West Sacramento. </p>



<p><strong>Q: &nbsp;Are there any costs I should know about?</strong><br/> A:&nbsp; Never. We don’t charge you a dime. We are direct buyers who are ready to pay you cash for your house right away. In fact, we will even provide you with a $5,000 non-refundable deposit to let you know how serious we are. </p>



<p><strong>Q: &nbsp;What situations are you able to help with?</strong><br/> A:&nbsp; After being in real estate for over 10 years, we have seen it all. Whether you are worried about foreclosure, bad tenants, repairs, or a difficult probate situation, our team is here to help. </p>



<p><strong>Q: &nbsp;What can I expect after providing my information?</strong><br/> A:&nbsp; <span> </span>After we receive your information, we will immediately begin our research on your property. We’ll take a look at the recent sales in your neighborhood, ensuring that you receive a fair price for your property. There is never any hassle, obligation, or pressure to sell your house. </p>



          </div>
          <div className="w-full md:w-1/3 md:pl-4">

<SeeTheDifferenceSidebar/>

            <div className="rounded-xl mb-2 shadow bg-white  mt-6 md:mt-0">
              <div className="px-8 pt-8">
                <h2 className="text-gray-800 font-bold text-2xl md:text-3xl leading-8">Get Your Fair Cash Offer: Start Below!</h2>
                <p class="text-gray-600 mb-2 leading-6 mt-1">We buy houses in any condition -- no commissions or fees and no obligation whatsoever. Start below by giving us a bit of information about your property or call 916-237-9457.</p>
              </div>


              <div className="p-8 pt-4"><ContactForm stacked={true} hideLabels={true} showPlaceholders={true} /></div>

            </div>
          </div>
        </div>
      </section>


      <HowItWorks />

    </Layout>
  );
};


export default FAQPage;

